<template>
  <div class="outNav">
    <div class="triangle" @click="setNavShow" :class="navShow ? 'active' : ''">
      <img
        src="../assets/image/jiantou_xia.png"
        class="bottomImgs"
        :class="navShow ? 'active' : ''"
      />
    </div>

    <div class="nav" :class="navShow ? 'active' : ''">
      <div
        :class="['navLinks']"
        v-for="(item, index) of navData"
        :key="index"
        @click="changeNav(index)"
        ref="refLeft"
      >
        <div>
          <img :src="item.icon" />
          <p>{{ item.name }}</p>
        </div>
      </div>

      <p class="thisNavBg" :style="{ left }"></p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { Debounce } from "@/utils/debounce";
// import { indexOf } from "ramda";
export default {
  data() {
    return {
      current: 0,
      left: "220px",
      menu_power: [],
      navData: [
        {
          icon: require("../assets/image/nav_sj.png"),
          name: "数字一张图",
          link: "index",
          line: 1,
        },
        {
          icon: require("../assets/image/nav_pp.png"),
          name: "品牌含山",
          link: "brand",
        },

        {
          icon: require("../assets/image/nav_ym.png"),
          name: "品种研发",
          link: "grow",
          line: 1,
        },
        {
          icon: require("../assets/image/nav-5.png"),
          name: "溯源中心",
          link: "traceability",
          line: 1,
        },
        {
          icon: require("../assets/image/sbgl.png"),
          name: "智慧农业",
          link: "gather",
        },
        {
          icon: require("../assets/image/service.png"),
          name: "农事服务",
          link: "service",
        },
        {
          icon: require("../assets/image/uav.png"),
          name: "无人农场",
          link: "uav",
        },
        {
          icon: require("../assets/image/cc.png"),
          name: "智慧仓储",
          link: "storage",
          line: 1,
        },
        {
          icon: require("../assets/image/nav_hg.png"),
          name: "烘干",
          link: "drying",
          line: 1,
        },
        // {
        //   icon: require("../assets/image/process.png"),
        //   name: "生产",
        //   link: "processList",
        //   line: 1,
        // },
        {
          icon: require("../assets/image/nav_grainStorage.png"),
          name: "成品粮仓储",
          link: "grainStorage",
          line: 1,
        },
        {
          icon: require("../assets/image/sc.png"),
          name: "在线商城",
          link: "shop",
        },
        // {
        //   icon: require("../assets/image/nav_xs.png"),
        //   name: "市场行情",
        //   link: "market",
        // },
        {
          icon: require("../assets/image/nav-2.png"),
          name: "土地管理",
          link: "land",
        },
        {
          icon: require("../assets/image/nav-99.png"),
          name: "作物管理",
          link: "crops",
        },

        {
          icon: require("../assets/image/nav-10.png"),
          name: "农事任务",
          link: "task",
          line: 1,
        },

        // {
        //   icon: require("../assets/image/nav-9.png"),
        //   name: "系统配置",
        //   link: "farm",
        // },
      ],
      navShow: true,
      setTime: null,
    };
  },
  watch: {
    nav: {
      handler(newVal) {
        if (newVal == 8 || newVal == 0) {
          this.current = newVal;
          setTimeout(() => {
            this.left = sessionStorage.getItem("navLeft") || "220px";
          }, 100);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      nav: (state) => state.user.nav,
      stateNavShow: (state) => state.app.navShow,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
  created() {
    this.initNav();
    this.current = +sessionStorage.getItem("navIndex") || 0;
    this.left = sessionStorage.getItem("navLeft") || "220px";
    this.navShow = this.stateNavShow;
  },
  beforeDestroy() {
    if (this.setTime) {
      clearTimeout(this.setTime);
      this.setTime = null;
    }
  },
  methods: {
    ...mapMutations("user", ["SET_NAV"]),
    ...mapMutations("app", ["SET_NAV_SHOW"]),
    initNav() {
      if (!this.farmInfo.menu_power) return;
      let menu_power = this.farmInfo.menu_power.split(",");
      this.navData.forEach((item, index) => {
        menu_power.forEach((items) => {
          if (item.name == items) {
            this.navData.splice(index, 1);
          }
        });
      });
    },
    goNav(e) {
      this.current = e;
      this.SET_NAV({ nav: e });
      this.left = this.$refs.refLeft[e].offsetLeft + "px";
      sessionStorage.setItem("navIndex", e);
      sessionStorage.setItem("childTitle", "0");
      sessionStorage.setItem("navLeft", this.left);
      this.setTime = setTimeout(() => {
        this.$router.push({
          name: this.navData[e].link,
        });
      }, 500);
    },

    changeNav(e) {
      Debounce(this.goNav(e), 1000);
    },
    setNavShow() {
      this.navShow = !this.navShow;
      this.SET_NAV_SHOW({ navShow: this.navShow });
    },
  },
};
</script>

<style lang="less" scoped>
.triangle {
  display: inline-block;
  height: 60px;
  width: 100px;
  overflow: hidden;
  transform: rotate(135deg);
  position: fixed;
  bottom: -50px;
  left: 46%;
  z-index: 1050;
  cursor: pointer;
  transition: all 0.5s;
  &.active {
    transform: rotate(135deg);
    left: 46%;
    bottom: 34px;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #3e90e5;
    transform-origin: 0 0;
    transform: rotate(45deg);
  }
  .bottomImgs {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 15%;
    transform: rotate(226deg);
    transition: all 0.5s;
    z-index: 1051;
    left: 11%;
    &.active {
      transform: rotate(45deg);
    }
  }
}
.nav {
  min-width: 1500px;
  height: 80px;
  background: url("../assets/image/navbg.png");
  background-size: cover;
  margin: 0 auto;
  position: fixed;
  bottom: -80px;
  left: 50%;
  z-index: 9998;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  padding: 0 114px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.5s;

  .navLinks {
    width: 118px;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    cursor: pointer;

    img {
      width: 42px;
      height: 42px;
    }

    p {
      line-height: 22px;
      color: #fff;
    }
    &::after {
      content: "";
      width: 1px;
      height: 58px;
      background: #125397;
      opacity: 0.35;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
    }

    &:last-of-type::after {
      display: none;
    }
  }

  &.active {
    transform: translate(-50%, -80px);
  }
}

.thisNavBg {
  width: 118px;
  height: 100%;
  position: absolute;
  left: var(left);
  top: 0;
  background: rgba(21, 92, 165, 0.39);
  z-index: 0;
  transition: left 0.5s;
}
</style>
